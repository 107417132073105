* {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
}

/*div {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
                                 
}*/

.b--dark-purple { 
  border-color: #591F56; 
}

.dark-purple { 
  color: #591F56; 
}

.bringToFront {
  z-index: 20
}

.b--aubergine { 
  border-color: #c2abc6; 
}

.b--thistle {
  border-color: #b977b3;
}

.b--dark-purple { 
  border-color: #591F56; 
}

.bg-dark-purple { 
  background: #591F56; 
}

.bg-custom { 
  background: #e9a7e9; 
}

.bg-lpe-teal {
  background-color: rgba(57, 171, 160, 0.5)
}

.lpe-teal {
  color: rgba(57, 171, 160, 0.5)
}

.b--lpe-teal {
  border-color: rgba(57, 171, 160, 1)
}

.bg-aubergine {
  background: #91449e;
}

.bg-thistle {
  background: #f7e7f5;
}

.lpe-blue {
  color: #3C506A
}

.bg-lpe-blue {
  background: #3C506A;
}

.b--lpe-blue {
  border-color: #3C506A;
}

.lpe-green {
  color: #39ABA0;
}

.bg-lpe-green {
  background: #39ABA0;
}

.b--lpe-green {
  border-color: #39ABA0;
}

.lpe-grey {
  color: #CFD5DC;
}

.bg-lpe-grey {
  background: #CFD5DC;
}

.bg-lpe-grey {
  background: #CFD5DC;
}

.b--lpe-grey {
  border-color: #CFD5DC;
}

.lpe-red {
  color: #D62516;
}

.bg-lpe-red {
  background: #D62516;
}

.b--lpe-red {
  border-color: #D62516;
}

.arrow-up{
  display: block;
  width: 0px;
  height: 0px;
  border:23px solid transparent;
  border-bottom-color: #591F56;
  border-top-width: 0px;
}

.top-fade {
  background-image: linear-gradient(to top, rgba(111,111,111,0), rgba(111,111,111,1));
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  min-width: 750px;
}

.AboutModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.selectedBox{
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);
}

.AboutSelectedBox{
  box-shadow: 0 0 0 99999px #591F56;
}

.fadeIn {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.fadeOut {
  animation: fadeout 1s;
  -moz-animation: fadeout 1s; /* Firefox */
  -webkit-animation: fadeout 1s; /* Safari and Chrome */
  -o-animation: fadeout 1s; /* Opera */
}

@keyframes fadeout {
  from {
      opacity:1;
  }
  to {
      opacity:0;
  }
}
@-moz-keyframes fadeout { /* Firefox */
  from {
      opacity:1;
  }
  to {
      opacity:0;
  }
}
@-webkit-keyframes fadeout { /* Safari and Chrome */
  from {
      opacity:1;
  }
  to {
      opacity:0;
  }
}
@-o-keyframes fadeout { /* Opera */
  from {
      opacity:1;
  }
  to {
      opacity: 0;
  }
}